import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import DestiniModal from "../DestiniModal/DestiniModal";
import { useInView } from 'react-intersection-observer';
import { useStaticQuery, graphql } from 'gatsby';

const ProductDetailsPromo = () => {

  const { ref: infoRef, inView: infoVisible } = useInView({ threshold: .2, triggerOnce: true });
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  }

  const data = useStaticQuery(graphql`
    query PromoQuery {
      allProductDetailPromoDataJson {
        edges {
          node {
            copy
            buttonLabel
            benefits {
              className
              copy
              subcopy
            }
            toast
          }
        }
      }
    }
  `);

  const copy = data.allProductDetailPromoDataJson.edges[0].node.copy;
  const buttonLabel = data.allProductDetailPromoDataJson.edges[0].node.buttonLabel;
  const benefits = data.allProductDetailPromoDataJson.edges[0].node.benefits;
  const toast = data.allProductDetailPromoDataJson.edges[0].node.toast;
  return (
    <section className="product-detail--promo" ref={infoRef}>
      <DestiniModal open={openModal} close={closeModal} />
      <Container className={`py-3 py-lg-5 product-detail--promo-container ${infoVisible ? 'show-container' : ''}`}>
        <Row className="section-inner">
          <Col className="col-xs-12 col-md-6 product-detail--promo-content">

            <p>{copy}</p>
            <button className="button destini-button" onClick={() => setOpenModal(true)}>{buttonLabel}</button>
          </Col>
          <Col className="col-xs-12 col-md-6 pl-lg-5">
            <div className="certs">
              <ul className="benefits">
                {benefits.map((benefit, i) =>
                  <li key={benefit.className} className={benefit.className} aria-label="chickens raised with no antibiotics ever">{benefit.copy}{benefit.subcopy && <><br /><span>{benefit.subcopy}</span></>}</li>
                )}
              </ul>

              <ul className="toast">
                {toast.map((el, i) =>
                  <li key={i}>{el}</li>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section >
  )
}
export default ProductDetailsPromo;
