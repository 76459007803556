import React from "react";
import { Helmet } from "react-helmet";

const FaqStructuredData = ({ data }) => {

  let structuredData = [];
  data.edges.forEach(element => {

    element.node.questions.forEach(obj => {
      let dataObj = {
        "@type": "Question",
        "name": obj.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": obj.answer
        }
      }
      structuredData.push(dataObj);
    });
  });

  const ldJsonObj = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": structuredData
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ldJsonObj)}</script>
    </Helmet>
  )
}

export default FaqStructuredData;