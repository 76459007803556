import React from "react";
import Layout from "../../components/Layout/Layout";
import ProductDetailCarousel from "../../components/ProductDetail/ProductDetailCarousel";
import ProductDetailsNutrition from "../../components/ProductDetail/ProductDetailsNutrition";
import ProductDetailsPromo from "../../components/ProductDetail/ProductDetailsPromo";

export default class ProductDetailPage extends React.Component {

  render() {

    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }

    return (
      <Layout
        metaTitle="Spinach Rustica Morning Protein Bites "
        metaDescription="With 10 grams of protein per serving, cage-free eggs, and delicious veggies like spinach, tomato, and green & red bell peppers, Lahli Morning Protein Bites in Spinach Rustica flavor will help nourish your mornings."
        schema={schema}
      >
        <div className="product-detail">
          <ProductDetailCarousel />
          <ProductDetailsPromo />
          <ProductDetailsNutrition />
        </div>
      </Layout>
    )
  }
}