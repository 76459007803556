import React from "react";
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Row, Col, Container } from "reactstrap"
import { useSpring, easings, animated } from "@react-spring/web";
import ProductImage from "../../img/lahli_spinach_rustica_marquee_600x622.png";
import BgIllustration from "../../img/lahli_spinach_rustica_illustration_BG_900x702.jpg";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroItemsQuery {
      allHeroDataJson {
        edges {
          node {
            copy
            headline
            illustrationALT
            ProductImgALT
            cta {
              copy
              link
            }
          }
        }
      }
    }
  `);

  const content = data.allHeroDataJson.edges[0].node;

  const productStyles = useSpring({
    from: { y: "100%", opacity: 0 },
    to: { y: "0", opacity: 1 },
    config: {
      duration: 600,
      easing: easings.easeInOut
    }
  });

  return (
    <section id="home-hero" className="hero">

      <div className="hero--background-illustration">
        <img src={BgIllustration} alt={content.illustrationALT} fetchpriority="high" />
      </div>
      <Container className={'section-inner'}>
        <Row>
          <Col className="hero--content" xs={{ order: 2, size: 12 }} md={{ size: 4, order: 1 }}>
            <h1>{content.headline}</h1>
            <p>{content.copy}</p>

            <Link className={`button`} to="/product/morning-protein-bites-spinach-rustica/">{content.cta.copy}</Link>

          </Col>

          <Col xs={{ order: 1, size: 12 }} md={{ size: 5, order: 2 }} className="hero--product-image">
            <animated.div style={{ ...productStyles }} >
              <img src={ProductImage} alt={content.ProductImgALT} fetchpriority="high" />
            </animated.div>

          </Col>
        </Row>
      </Container>
    </section >
  )
}

export default Hero;
