import React from "react";
import { useInView } from 'react-intersection-observer';
import { Container, Row, Col } from "reactstrap";
import sectionBottom from "../../img/curved-border-bottom-orange.svg";
import Curve from "../../img/faq-curve-1.svg";
import CurveMobile from "../../img/faq-curve-1-mobile.svg";
import AnimatedCurves from "../AnimatedCurves/AnimatedCurves";

const StandardPageHeader = ({ header, subheader }) => {
  const { ref: curveRef, inView: curveVisible } = useInView({ threshold: .5, delay: 600 });
  return (
    <div className="standard-page-header">
      <Container className="standard-page-header--inner">

        <Row className="section-inner">
          <Col className="col-xs-12 col-md-6 pr-5">

            <h1>{header}</h1>
            {subheader && <strong>{subheader}</strong>}

          </Col>
        </Row>
      </Container>
      <div className="svg-container standard-page-header--curve-1" ref={curveRef}>

        {curveVisible && <AnimatedCurves
          curve={Curve}
          curveMobile={CurveMobile}
          id={`standard-header`}
        />}
      </div>
      <div className="svg-curved-border standard-page-header--bg-bottom">
        <img className="svg-curved-border-image" src={sectionBottom} alt="" />
      </div>
    </div>
  )
}

export default StandardPageHeader;
