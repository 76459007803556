import React from "react";
import { Modal, ModalBody } from 'reactstrap';
import {Destini} from "@tyson-foods/react-component-library";

const DestiniModal = ({ open, close }) => {

  const closeModal = () => {
    close();
  }

  return (
    <div>
      <Modal isOpen={open}>
        <button className="close-button" onClick={() => closeModal()}>Close</button>
        <ModalBody>
          <Destini siteID='lahli' showMap={true} />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default DestiniModal;
