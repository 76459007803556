import React from "react";
import { useInView } from 'react-intersection-observer';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col, Container } from "reactstrap";
import Biker1 from "../../img/biker-1.inline.svg";
import Biker2 from "../../img/biker-2.inline-latest.svg";
import Curve1 from "../../img/home-animated-curve-1-1.inline.svg";
import Curve1Mobile from "../../img/home-animated-curve-1-mobile.svg";
import Curve2 from "../../img/home-animated-curve-2.svg";
import Curve2Mobile from "../../img/home-animated-curve-2-mobile.svg";
import productImg from "../../img/lahli_bites_ingredients_HP.png";
import BGTop from "../../img/home-module-1-top.png";
import BGTopMobile from "../../img/home-module-1-top-mobile.png";
import BGBottom from "../../img/home-module-1-bottom.png";
import BGBottomMobile from "../../img/home-module-1-bottom-mobile.png";
import AnimatedCurves from "../AnimatedCurves/AnimatedCurves";

const ContentWithImage = () => {

  const { ref: biker1Ref, inView: biker1Visible } = useInView({ threshold: .75, delay: 2300 });
  const { ref: biker2Ref, inView: biker2Visible } = useInView({ threshold: .75, delay: 2300 });
  const { ref: curve1Ref, inView: curve1Visible } = useInView({ threshold: .75 });
  const { ref: curve2Ref, inView: curve2Visible } = useInView({ threshold: 0.33 });
  const { ref: contentRef, inView: contentVisible } = useInView({ threshold: .25, triggerOnce: true });
  const { ref: productRef, inView: productVisible } = useInView({ threshold: .33, delay: 1000, triggerOnce: true });

  const data = useStaticQuery(graphql`
    query HomeOneItemsQuery {
      allHomeContentOneDataJson {
        edges {
          node {
            copy
            headline
            productImgALT
          }
        }
      }
    }
  `);

  const headline = data.allHomeContentOneDataJson.edges[0].node.headline;
  const copy = data.allHomeContentOneDataJson.edges[0].node.copy;
  const alt = data.allHomeContentOneDataJson.edges[0].node.productImgALT;
  return (

    <section className="content-with-image module-1">
      <div className="svg-curved-border module-1-top">
        <img className="svg-curved-border-image lg" src={BGTop} alt="" />
        <img className="svg-curved-border-image sm" src={BGTopMobile} alt="" />
      </div>

      <div className={`bike-animation-1 ${biker1Visible ? 'animate-biker' : ''}`} ref={biker1Ref}>
        <img className="svg-animation-content biker-1" src={Biker1} alt="" />
      </div>

      <div className={`bike-animation-2 ${biker2Visible ? 'animate-biker' : ''}`} ref={biker2Ref}>
        <img className="svg-animation-content" src={Biker2} alt="" />
      </div>

      <div className="svg-container curve-animation curve-1" ref={curve1Ref}>
        {curve1Visible && <AnimatedCurves
          curve={Curve1}
          curveMobile={Curve1Mobile}
          id={`home-curve-1`}
        />}
      </div>

      <div className="content-wrapper">
        <Container className={'module-inner'}>
          <Container className={'section-inner'}>
            <Row>
              <Col className={`col-12 col-md-4`}>

              </Col>
              <Col className="col-12 col-md-6">
                <div ref={contentRef} className={`product-content ${contentVisible ? 'animate-content' : ''}`}>
                  <h2>{headline}</h2>
                  {copy.map((el, idx) =>
                    <p key={idx}>{el}</p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div className="product-img-container" ref={productRef}>
        <img src={productImg} alt={alt} className={`animatable ${productVisible ? 'animate-product' : ''}`} />
      </div>

      <div className="svg-container curve-animation curve-2" ref={curve2Ref}>
        {curve2Visible && <AnimatedCurves
          curve={Curve2}
          curveMobile={Curve2Mobile}
          id={`home-curve-2`}
        />}
      </div>

      <div className="svg-curved-border module-1-bottom">
        <img className="svg-curved-border-image lg" src={BGBottom} alt="" />
        <img className="svg-curved-border-image sm" src={BGBottomMobile} alt="" />
      </div>
    </section>

  )
}

export default ContentWithImage;