import * as React from "react";
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { Row, Col, Container } from "reactstrap";
import productImg from "../../img/lahli_bites_clean_simple_V2_600x427.png";

const HomeModuleTwo = () => {
  const { ref: moduleTwoRef, inView: moduleTwoVisible } = useInView({ threshold: 0.2, triggerOnce: true });
  const { ref: moduleTwoProductRef, inView: moduleTwoProductVisible } = useInView({ threshold: 0.25, triggerOnce: true });

  const data = useStaticQuery(graphql`
    query HomeTwoItemsQuery {
      allHomeContentTwoDataJson {
        edges {
          node {
            copy
            headline
            disclaimer
            productImgALT
          }
        }
      }
    }
  `);

  const headline = data.allHomeContentTwoDataJson.edges[0].node.headline;
  const copy = data.allHomeContentTwoDataJson.edges[0].node.copy;
  const disclaimer = data.allHomeContentTwoDataJson.edges[0].node.disclaimer;
  const alt = data.allHomeContentTwoDataJson.edges[0].node.productImgALT;

  return (
    <section className="content-with-image module-2">
      <Container className={'module-inner'}>
        <Container className={'section-inner'}>
          <Row>

            <Col className="col-12 col-md-6 mt-0 mt-lg-5" style={{ "position": "relative" }}>
              <div ref={moduleTwoRef} className={`product-content ${moduleTwoVisible ? 'animate-content' : ''}`}>
                <h2>{headline}</h2>
                {copy.map((el, idx) =>
                  <p key={idx}>{el}</p>
                )}

                <Link to="/faq/" className="button">Learn More</Link>
                <p className="small lg-only" >
                  {disclaimer.map((el, idx) =>

                    <span key={idx}>{el}<br /></span>
                  )}
                </p>

              </div>
            </Col>

          </Row>
        </Container>
      </Container>
      <div className="product-img-container" ref={moduleTwoProductRef}>
        <img src={productImg} alt={alt} className={`${moduleTwoProductVisible ? 'animate-product' : ''}`} />
      </div>
      <p className="small sm-only" >
        <br /><br />
        {disclaimer.map((el, idx) =>

          <span key={idx}>{el}<br /></span>
        )}
      </p>
    </section >
  )
}

export default HomeModuleTwo;
