import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from "reactstrap";
import DestiniModal from "../DestiniModal/DestiniModal";
import StandardPageHeader from "../StandardPageHeader/StandardPageHeader";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import FaqStructuredData from "./FaqStructuredData";

const Faq = () => {
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  }

  const data = useStaticQuery(graphql`
    query FaqQuery {
      allFaqDataJson {
        edges {
          node {
            headline
            anchor
            questions {
              answer
              destiniButton
              disclaimer
              question
              link {
                internal
                link
                text
              }
            }
          }
        }
      }
    }
  `);

  const content = data.allFaqDataJson.edges;

  return (
    <section className="faq">

      <FaqStructuredData data={data.allFaqDataJson} />

      <StandardPageHeader header="FAQs" subheader="Common questions with good clean answers." />
      <DestiniModal open={openModal} close={closeModal} />
      <div className="faq--content">
        <Container className="section-inner pt-4 pt-lg-5">
          <Row>
            <Col className="col-xs-12 col-md-4 faq--content-menu">
              <ul>
                {content.map((item) =>
                  <li key={item.node.anchor}><a href={`#${item.node.anchor}`}>{item.node.headline}</a></li>
                )}
              </ul>
            </Col>
            <Col className="col-xs-12 col-md-8 faq--content-accordion">
              {content.map((item, i) =>
                <div key={`${item.node.anchor}-${i}`}>
                  <h3 id={item.node.anchor}>{item.node.headline}</h3>
                  <Accordion allowZeroExpanded>
                    {item.node.questions.map((question, i) =>
                      <AccordionItem key={i}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <span>{question.question}</span>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p dangerouslySetInnerHTML={{ __html: question.answer }}></p>
                          {question.link && question.link.internal && <Link to={question.link.link}>{question.link.text}</Link>}
                          {question.link && !question.link.internal && <a href={question.link.link} rel="noreferrer" target="_blank">{question.link.text}</a>}
                          {question.destiniButton && <button className="destini-button" onClick={() => setOpenModal(true)}>Find a store near you</button>}
                          {question.disclaimer && question.disclaimer.map((text, i) =>
                            <p className="small" key={i}>{text}<br /></p>
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    )}

                  </Accordion>
                </div>
              )}

            </Col>
          </Row>
        </Container>
      </div >
    </section >
  )
}

export default Faq;
