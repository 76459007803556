import React from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import Curve from "../../img/product-detail-curve-1.svg";
import CurveMobile from "../../img/product-detail-curve-1-mobile.svg";
import CarouselBottom from "../../img/product-detail-carousel-border-bottom-01.svg";
import { useInView } from 'react-intersection-observer';
import AnimatedCurves from "../AnimatedCurves/AnimatedCurves";

const CarouselLarge = ({ carouselSettings, images }) => {

  const { ref: curveRef, inView: curveVisible } = useInView({ threshold: .5, delay: 600 });

  return (
    <Container>
      <Row>
        <Col className="product-detail--carousel-col">
          <div className="product-detail--carousel-main">
            <Container className="section-inner">
              <Row>
                <Col className="col-xs-12 carousel-offset">
                  <Slider {...carouselSettings}>
                    {images.map((image, i) =>
                      <div key={i}>
                        <img src={image} alt="lahli morning protein bites harvest product packshot front" />
                      </div>
                    )}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="svg-container product-detail-curve-1 sm" ref={curveRef}>
            {curveVisible && <AnimatedCurves
              curve={Curve}
              curveMobile={CurveMobile}
              id={`prod-detail-1`}
            />}
          </div>
          <div className="svg-curved-border product-detail--carousel-border sm">
            <img className="svg-curved-border-image" src={CarouselBottom} alt="" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="product-detail--carousel-content section-inner">
            <h1 className="pack-condensed">Morning Protein Bites</h1>
            <hr />
            <h2>Harvest</h2>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default CarouselLarge;
