import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  Container
} from 'reactstrap';

import logo from '../../img/lahli-logo.svg';

const navItems = [
  { title: 'Products', url: '/product/morning-protein-bites-spinach-rustica/' },
  { title: 'FAQs', url: '/faq/' },
  { title: 'Contact Us', url: '/contact-us/' },
]

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      pageMounted: false
    }
  }

  componentDidMount() {
    // page load/ reveal nav
    this.setState({
      pageMounted: true
    });

    // track scrolling - hide when scroll down/reveal on scroll up
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      this.setState({
        pageMounted: scrollY > lastScrollY ? false : true
      });

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;

    };

    const onScroll = () => {

      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <Navbar
              id="navigation"
              role="navigation"
              expand="xl"
              className={`d-print-none ${this.state.pageMounted ? 'active' : ''}`}
            >
              <Container className={'section-inner'}>
                <Link
                  to="/"
                  title={data.site.siteMetadata.title}
                  className="d-block nav-logo navbar-brand text-center"
                >
                  <img
                    className="logo"
                    src={logo}
                    alt="lahli brand logo"
                  />
                </Link>

                <Collapse
                  id="main-navigation"
                  className={this.state.isOpen ? 'show' : ''}
                  navbar
                >
                  <Nav className="navbar-nav--primary ml-xl-4 ml-xxl-5" navbar>
                    {navItems.map((navItem, i) => {
                      return (
                        <NavItem key={i}>
                          <Link
                            to={navItem.url}
                            className="nav-link"
                            activeClassName="nav-link--active"
                            partiallyActive={true}
                            style={{ '--index': i }}
                          >
                            {navItem.title}
                          </Link>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </Collapse>

                <button
                  className={`btn btn-none hamburger hamburger--spin navbar-toggler ${this.state.isOpen ? 'is-active' : ''}`}
                  onClick={this.toggle}
                  aria-controls="main-navigation"
                  aria-expanded={this.state.isOpen}
                  aria-label="Toggle navigation"
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </Container>
            </Navbar>
          </React.Fragment>
        )}
      />
    )
  }
}
