import React from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import carouselImg1 from "../../img/lahli_spinach_rustica_morning_protein_bites_FOP_560x667.png";
import carouselImg2 from "../../img/lahli_spinach_rustica_morning_protein_bites_BOP_560x667.png";
import Curve from "../../img/product-detail-curve-1.svg";
import CurveMobile from "../../img/product-detail-curve-1-mobile.svg";
import CarouselBottom from "../../img/product-detail-carousel-border-bottom-01.svg";
import { useInView } from 'react-intersection-observer';
import AnimatedCurves from "../AnimatedCurves/AnimatedCurves";

const CarouselLarge = ({ carouselSettings, images }) => {

  const { ref: curveRef, inView: curveVisible } = useInView({ threshold: .5, delay: 600 });

  return (
    <>
      <div className="product-detail--carousel-main large">
        <Container className="section-inner">
          <Row>
            <Col className="col-xs-12 col-md-7 carousel-offset">
              <Slider {...carouselSettings}>
                <div>
                  <img src={carouselImg1} alt=" lahli morning protein bites Spinach Rustica product packshot front" />
                </div>
                <div>
                  <img src={carouselImg2} alt="lahli morning protein bites Spinach Rustica product packshot back" />
                </div>
              </Slider>
            </Col>
            <Col className="col-xs-12 col-md-5 mb-4 mb-lg-0">
              <div className="product-detail--carousel-content">
                <h1 className="pack-condensed">Morning Protein Bites</h1>
                <hr />
                <h2>Spinach Rustica</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="svg-container product-detail-curve-1" ref={curveRef}>
        {curveVisible && <AnimatedCurves
          curve={Curve}
          curveMobile={CurveMobile}
          id={`prod-detail-1`}
        />}
      </div>
      <div className="svg-curved-border product-detail--carousel-border">
        <img className="svg-curved-border-image" src={CarouselBottom} alt="" />
      </div>
    </>
  )
}

export default CarouselLarge;
