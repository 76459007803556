import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, Link } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import classNames from 'classnames';
import Notification from "../Notification/Notification";
import { Button } from "reactstrap";
import { titleMaker } from '../../extensions';

const TemplateWrapper = ({
  pageTitle,
  metaTitle,
  metaDescription,
  metaKeywords,
  className,
  children,
  schema,
}) => {
  const title = titleMaker(metaTitle, pageTitle)
  const classes = classNames(className)

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={`${title ? title + " | " : ""}${data.site.siteMetadata.title
              }`}
            meta={[
              { name: 'description', content: metaDescription },
              { name: 'keywords', content: metaKeywords },
              { name: 'theme-color', content: '#495057' },
            ]}
          >
            {schema && (
              <script type="application/ld+json">
                {JSON.stringify(schema)}
              </script>
            )}
          </Helmet>

          <Header />

          <main className={classes}>{children}</main>

          <Footer />
          <Notification
           text="Your privacy is important to us. We use cookies to ensure you get the best experience on this website. For more information about how to control your cookies click learn more."
           className="notification--gdpr"
           buttonText="Accept"
           buttons={
             <Link to="/privacy-policy">
               <Button
                 color={'Danger'}
                 buttonColor={'Danger'}
                 type="button"
               >
                 Learn More
               </Button>
             </Link>
           }

          />
        </React.Fragment>
      )}
    />
  )
}

export default TemplateWrapper
