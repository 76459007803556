import React from "react";
import { useInView } from 'react-intersection-observer';
import { useStaticQuery, graphql, Link } from 'gatsby';
//import FacebookIcon from '../../img/icon-facebook.svg';
//import TwitterIcon from '../../img/icon-twitter.svg';
//import InstagramIcon from '../../img/icon-instagram.svg';
import FooterBG from '../../img/bg-footer-curve.svg';
import FooterBGLarge from '../../img/bg-footer-curve-lg.svg';
import FooterCurve from '../../img/curve-footer.svg';
import FooterCurveMobile from '../../img/curve-footer-mobile-new.svg';
import AnimatedCurves from "../AnimatedCurves/AnimatedCurves";

const Footer = () => {

  const { ref: footerRef, inView: footerVisible } = useInView({ threshold: 1 });

  const data = useStaticQuery(graphql`
    query FooterItemsQuery {
      allFooterDataJson {
        edges {
          node {
            path
            text
          }
        }
      }
    }
  `);

  return (
    <footer className="footer pt-3">
      <h3>{footerVisible}</h3>
      <div className="svg-container footer--bg">
        <img className="svg-content footer-bg-md" src={FooterBGLarge} alt="" />
        <img className="svg-content footer-bg-xs" src={FooterBG} alt="" />
      </div>
      <div className="svg-container footer-curve" ref={footerRef}>
        {footerVisible && <AnimatedCurves
          curve={FooterCurve}
          curveMobile={FooterCurveMobile}
          id={`footer`}
        />}
      </div>
      <div className="footer--inner">
        <div className="section-inner">
          <nav>
            <ul>
              <li className="footer--inner-logo mr-lg-4" aria-label="lahli brand logo">
                <Link to="/">
                  Lahli Home
                </Link>
              </li>
              {/**<li className="footer--inner-social-icons mr-lg-4">
                <a href="https://facebook.com"><img src={FacebookIcon} alt="placeholder for social icons" /></a>
                <a href="https://twitter.com"><img src={TwitterIcon} alt="placeholder for social icons" /></a>
                <a href="https://instagram.com"><img src={InstagramIcon} alt="placeholder for social icons" /></a>
              </li>**/}
              {data.allFooterDataJson.edges.map((el) =>
                <li key={el.node.path} className="mr-lg-4">
                  <Link to={`/${el.node.path}`}>
                    {el.node.text}
                  </Link>
                </li>
              )}
              <li>
                <a href="https://app.keysurvey.com/f/1463959/4ffd/" target="_new" rel="">
                  Do Not Sell My Personal Information
                </a>
              </li>
            </ul>

            <div className="footer--inner-bottom">
              {/**<div className="social-strip mb-lg-4">
                <a href="https://facebook.com"><img src={FacebookIcon} alt="placeholder for social icons" /></a>
                <a href="https://twitter.com"><img src={TwitterIcon} alt="placeholder for social icons" /></a>
                <a href="https://instagram.com"><img src={InstagramIcon} alt="placeholder for social icons" /></a>
              </div>**/}
              <span className="footer--inner-copyright">
                <sup>&copy;</sup> Copyright 2024 Lahli Foods All Rights Reserved
              </span>
            </div>
          </nav>
        </div>
      </div>
    </footer>

  )
}

export default Footer;