import React from "react";
import Layout from "../components/Layout/Layout";
import Faq from "../components/FAQ/Faq";

export default class FaqPage extends React.Component {
  render() {

    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }
    return (
      <Layout
        metaTitle="Frequently Asked Questions "
        metaDescription="Have a question about Lahli breakfast bites? Check out our FAQs for answers to your questions about ingredients, nutritional information, preparation, and where to buy."
        schema={schema}
      >
        <Faq />
      </Layout>
    )
  }
}