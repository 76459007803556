/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactVivus from "react-vivus";

const AnimatedCurves = ({ curve, curveMobile, id }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth >= 1024 ? false : true);

  const windowSize = () => {
    return window.innerWidth >= 1024 ? false : true;
  }

  const updateResizeState = (windowSize) => {
    setIsMobile(windowSize);
  }

  useEffect(() => {
    updateResizeState(windowSize);

    window.addEventListener('resize', () => {
      updateResizeState(windowSize);
    })
    return () => {
      window.removeEventListener('resize', updateResizeState);
    }
  })

  return (
    <div className="vivus">
      <ReactVivus
        id={id}
        option={{
          file: isMobile ? curveMobile : curve,
          animTimingFunction: 'EASE',
          duration: 60,
          type: 'oneByOne'
        }}
        callback={() => { }}
      />
    </div>
  )
}

export default AnimatedCurves;
