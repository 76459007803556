import React from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { useStaticQuery, graphql } from 'gatsby';
import "@reach/tabs/styles.css";
import productImg from "../../img/lahli_spinach_rustica_bites2_554x462.png";
import { Container, Row, Col } from "reactstrap";
import { useInView } from 'react-intersection-observer';
import NutritionTop from "../../img/nutrition-top-bg-01.svg";
import NutritionTopMobile from "../../img/nutrition-top-mobile-bg-new.svg";
//import NutritionBottom from "../../img/curved-border-bottom-orange.svg";
import NutritionImage from "../../img/lahli_spinach_rustica_morning_protein_bites_nutrition.png";
import Curve2 from "../../img/product-detail-curve-2.svg";
import Curve2Mobile from "../../img/product-detail-curve-2-mobile.svg";
//import Curve3 from "../../img/product-detail-curve-3.svg";
//import Curve3Mobile from "../../img/product-detail-curve-3-mobile.svg";
import AnimatedCurves from "../AnimatedCurves/AnimatedCurves";

const ProductDetailsNutrition = () => {

  const { ref: curve2Ref, inView: curve2Visible } = useInView({ threshold: .75 });
  const { ref: bitesRef, inView: bitesVisible } = useInView({ threshold: .75, triggerOnce: true, delay: 1000 });
  //const { ref: curve3Ref, inView: curve3Visible } = useInView({ threshold: .75 });

  const data = useStaticQuery(graphql`
    query NutritionQuery {
      allProductDetailNutritionDataJson {
        edges {
          node {
            ingredients
            ingredientList {
              list
              disclaimer
            }
            disclaimers
            preparation {
              headline
              instructions
              steps {
                headline
                step
                disclaimer {
                  headline
                  copy
                }
              }
            }
          }
        }
      }
    }
  `);

  const ingredients = data.allProductDetailNutritionDataJson.edges[0].node.ingredients;
  const ingredientList = data.allProductDetailNutritionDataJson.edges[0].node.ingredientList;
  const disclaimers = data.allProductDetailNutritionDataJson.edges[0].node.disclaimers;
  const preparation = data.allProductDetailNutritionDataJson.edges[0].node.preparation;

  return (
    <section className="product-detail--nutrition">
      <div className="svg-curved-border product-detail--nutrition-bg-top">
        <img className="svg-curved-border-image sm" src={NutritionTopMobile} alt="" />
        <img className="svg-curved-border-image lg" src={NutritionTop} alt="" />
      </div>
      <div className={`product-detail--nutrition-img ${bitesVisible ? 'animate-product' : ''}`} ref={bitesRef}>
        <img src={productImg} alt="two lahli morning protein bites in harvest flavor" />
      </div>
      <div className="svg-container product-detail-curve-2" ref={curve2Ref}>
        {curve2Visible && <AnimatedCurves
          curve={Curve2}
          curveMobile={Curve2Mobile}
          id={`prod-detail-2`}
        />}
      </div>

      <Tabs className="product-detail--nutrition-inner">
        <Container className="product-detail--nutrition-inner-tabs section-inner">
          <Row>
            <Col className="col-xs-12 col-md-6">
              <TabList>
                <Tab className="col-xs-6 col-md-4 nutrition"><h4>Ingredients<br />& Nutrition</h4></Tab>
                <Tab className="col-xs-6 col-md-4 ingredients"><h4>How to Prepare</h4></Tab>
              </TabList>
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-md-12 pt-4">
              <TabPanels>
                <TabPanel >
                  <Container>
                    <Row className="two-column-tab">
                      <Col className="col-xs-12 col-md-4 pb-4">
                        <p className="sans-bold">
                          {ingredients.map((ingredient) =>
                            <span key={ingredient}>{ingredient}<br /></span>
                          )}
                        </p>
                        <p className="medium">{ingredientList.list}<br />{ingredientList.disclaimer}</p>

                        {disclaimers.map((disclaimer, idx) =>
                          <p className="small" key={disclaimer}>{disclaimer}</p>
                        )}

                      </Col>
                      <Col className="col-xs-12 col-md-8 pl-md-5 product-detail--nutrition-inner-label">
                        <img src={NutritionImage} alt="nutritional information for lahli morning protein bites in harvest flavor" />
                      </Col>
                    </Row>
                  </Container>
                </TabPanel>
                <TabPanel className="col-xs-12 col-md-4 padding-offset">
                <>
                  {
                    preparation.map(({headline, steps, instructions}) => {
                      return (
                        <>
                          <h5>{headline}</h5>

                          {
                            steps.map((step, i) => (
                              <div className="prep-container" key={`${step}-${i}`}>
                                <span className="list-bg">{i + 1}</span>
                                <div>
                                  <strong>{step.headline}</strong>
                                  <p className="medium">{step.step}</p>
                                  {step.disclaimer && <p className="medium"><strong>{step.disclaimer.headline}</strong> {step.disclaimer.copy}</p>}
                                </div>
                              </div>
                            ))
                          }

                          <p className="medium">
                            {
                              instructions.map((el, i) => (
                                <span key={`${el}-${i}`}>{el}<br /></span>
                              ))
                            }
                          </p>
                        </>
                      )}
                    )                    
                  }
                  </>
                </TabPanel>
              </TabPanels>
            </Col>
          </Row>
        </Container>
      </Tabs>

      {/**<div className="svg-container product-detail-curve-3" ref={curve3Ref}>
        {curve3Visible && <AnimatedCurves
          curve={Curve3}
          curveMobile={Curve3Mobile}
          id={`prod-detail-3`}
        />}
      </div>
      <div className="svg-curved-border product-detail--nutrition-bg-bottom">
        <img className="svg-curved-border-image" src={NutritionBottom} alt="" />
      </div>
      **/}
    </section >


  )
}
export default ProductDetailsNutrition;
