import React from "react";
import Layout from "../components/Layout/Layout";
import StandardPageHeader from "../components/StandardPageHeader/StandardPageHeader";
import {AboutOurAds} from "@tyson-foods/react-component-library";
import { Container } from "reactstrap";

export default class TermsOfUsePage extends React.Component {
  render() {
    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }
    return (
      <Layout
        metaTitle="About Our Ads "
        metaDescription="Information about the advertising practices of Lahli Foods."
        schema={schema}
      >
        <Container className="offset-padding">
          <StandardPageHeader header="About Our Ads" subheader={null} />
          <AboutOurAds showTitle={false} className="mt-5 pt-5 section-inner" />
        </Container>
      </Layout>
    )
  }
}
