import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"
import StandardPageHeader from "../components/StandardPageHeader/StandardPageHeader";
import {TermsOfUse} from "@tyson-foods/react-component-library";
import { Container } from "reactstrap"

export default class TermsOfUsePage extends React.Component {
  render() {
    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }
    return (
      <Layout
        metaTitle="Terms of Use "
        metaDescription="Terms and conditions for use of Lahli Foods website."
        schema={schema}
      >
        <Container className="offset-padding">
          <StandardPageHeader header="Terms & Conditions" subheader={null} />
          <TermsOfUse
            className="mt-5 pt-5 section-inner"
            privacyPolicyLink={
              <Link to="/privacy-policy/">PRIVACY POLICY</Link>
            }
            website="https://www.lahli.com"
            showTitle={false}
          />
        </Container>
      </Layout>
    )
  }
}
