import React from "react";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Layout from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";
import ContentWithImage from "../components/ContentWithImage/ContentWithImage";
import HomeModuleTwo from "../components/HomeModuleTwo/HomeModuleTwo";
//modal for surveys: import SurveyModal from "../components/SurveyModal/SurveyModal"; 

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: null,
      // modalOpen: false
    }
  }

  componentDidMount = () => {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    /**
     * necessary for modal implementation:
      setTimeout(() => {
      this.setState({
      modalOpen: true
      })
      }, 2000); 
    */
  }

  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth > 1024 ? false : true
    });
  }

  componentWillUnmount = () => {
    /**
     * state for modal implementation:
      this.setState({
        modalOpen: false
      })
    */
    window.removeEventListener("resize", this.handleResize);
  }
  /**
   * code block necessary for survey modal implementation:
    closeModal = () => {
    this.setState({
      modalOpen: false
    })  
    }
  */

  render() {

    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }

    let mobileSpeed = this.state.isMobile ? -5 : -20
    return (
      <Layout
        metaTitle="Clean & Simple Protein Breakfast Bites"
        metaDescription="Breakfast bites made with flavorful veggies, cage-free scrambled eggs, and all-natural chicken sausage. Lahli Morning Protein Bites are a gluten free start to your day."
        schema={schema}
      >
        <div className="homepage-container">
          <ParallaxProvider>
            <Parallax speed={mobileSpeed}>
              <Hero />
            </Parallax>
            <Parallax speed={0}>
              <ContentWithImage />
            </Parallax>
            <Parallax speed={20}>
              <HomeModuleTwo />
            </Parallax>
          </ParallaxProvider>
        </div>
        {/* <SurveyModal open={this.state.modalOpen} close={this.closeModal} /> */}
      </Layout>
    )
  }
}
