import React from "react";
import Layout from "../components/Layout/Layout";
import StandardPageHeader from "../components/StandardPageHeader/StandardPageHeader";
import { Container } from "reactstrap";
import {ConsumerAffairsForm} from "@tyson-foods/react-component-library";

export default class ContactUsPage extends React.Component {
  render() {
    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }
    return (

      <Layout
        metaTitle="Contact Us "
        metaDescription="We love hearing from our customers. Please use this form to contact Lahli Foods. "
        schema={schema}
      >
        <StandardPageHeader header="Contact Us" subheader={null} />
        <Container className="section-inner">
          <h2 className="py-5 narrow-header">We love hearing from our customers. Please use this form to contact Lahli Foods.</h2>
          <ConsumerAffairsForm formHandlerApiUri={process.env.GATSBY_FORM_HANDLER_API_URL} formHandlerApiKey={process.env.GATSBY_FORM_HANDLER_API_KEY} showTitle={false} />
        </Container>

      </Layout >
    )
  }
}