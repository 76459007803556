import React from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import StandardPageHeader from "../components/StandardPageHeader/StandardPageHeader";
import {PrivacyPolicy} from "@tyson-foods/react-component-library";
import { Container } from "reactstrap";

export default class PrivacyPolicyPage extends React.Component {
  render() {
    const schema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "www.lahli.com"
    }
    return (
      <Layout
        metaTitle="Privacy Policy "
        metaDescription="This Privacy Notice applies to the websites, apps, platforms where it appears, as well as any other points of contact with the Company."
        schema={schema}
      >
        <Container className="offset-padding">
          <StandardPageHeader header="Privacy Policy" subheader={null} />
          <PrivacyPolicy
            className="mt-5 pt-5 section-inner"
            aboutOurAdsLink={<Link to="/about-our-ads/">HERE</Link>}
            showTitle={false}
          />
        </Container>
      </Layout>
    )
  }
}
