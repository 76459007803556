import React, { useEffect, useState } from "react";
import carouselImg1 from "../../img/lahli_spinach_rustica_morning_protein_bites_FOP_560x667.png";
import carouselImg2 from "../../img/lahli_spinach_rustica_morning_protein_bites_BOP_560x667.png";
import CarouselLarge from "./CarouselLarge";
import CarouselSmall from "./CarouselSmall";

const ProductDetailCarousel = () => {

  const isBrowser = typeof window !== "undefined";

  const windowSize = () => {
    if (!isBrowser) return false;
    return window.innerWidth >= 1024 ? false : true;
  }

  const [isMobile, setIsMobile] = useState(() => windowSize());
  const carouselImages = [carouselImg1, carouselImg2]
  const updateBreakpoint = () => {
    setIsMobile(() => windowSize());
  }

  useEffect(() => {
    updateBreakpoint();

    window.addEventListener('resize', () => {
      updateBreakpoint();
    });

    return () => {
      window.removeEventListener('resize', () => updateBreakpoint());
    }
  });

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className="product-detail--carousel">
      {isMobile ? <CarouselSmall carouselSettings={carouselSettings} images={carouselImages} /> : <CarouselLarge carouselSettings={carouselSettings} images={carouselImages} />}
    </section>
  )
}

export default ProductDetailCarousel;
