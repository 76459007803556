import React from 'react';
import Layout from '../components/Layout/Layout';
import { Container } from "reactstrap";
import StandardPageHeader from "../components/StandardPageHeader/StandardPageHeader";
import { Link } from 'gatsby';

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout metaTitle="Page Not Found">
        <Container className="offset-padding">
          <StandardPageHeader header="Page Not Found" subheader={null} />
          <section className="py-5 offset-padding">
            <div className="content text-center my-5 py-5">
              <p className="mb-5">
                <strong>
                  Oh no! While we always have clean, feel-good food, it seems we can't find that page.
                </strong>
              </p>
              <Link className="button" to="/">
                Want to try again?
              </Link>
            </div>
          </section>
        </Container>
      </Layout>
    )
  }
}
