import { upperFirst } from 'lodash'
import React from 'react'

function getSuperscript(title) {
  return (
    (title &&
      typeof title === 'string' &&
      title.split('®').map((textSplit, i) =>
        i !== title.split('®').length - 1 ? (
          <span>
            {textSplit}
            <sup>®</sup>
          </span>
        ) : (
          <span>{textSplit}</span>
        )
      )) ||
    title
  )
}

function titleMaker(metaTitle, pageTitle) {
  if (metaTitle) {
    return metaTitle
  }
  return pageTitle ? upperFirst(pageTitle) : ''
}

function getImageType(type) {
  return type === 'recipe' ? 'background-image' : ''
}

function getPacificoFont(title) {
  return title && typeof title === 'string' && title.includes('Say it')
    ? 'pacifico'
    : ''
}

export { getSuperscript, getPacificoFont, getImageType, titleMaker }
